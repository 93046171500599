import axios from "axios";

export default class ApiClass {

    // LOCAL LINKS

    static baseUrl = 'https://admin.lbmsolutions.in/backend/public/api/';
    // static baseUrl = 'http://192.168.11.116:8500/api/';




    // ******************************* LARAVEL API GET REQUEST *******************************************
    static postRequest(apiUrl, isToken = true, formData = null, headers = null, params = null) {
        return axios.post(this.baseUrl + apiUrl, formData, this.config(isToken, headers, params)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }

    // ********************************* LARAVEL API GET REQUEST **************************************
    static getRequest(apiUrl, isToken = true, headers = null, params = null) {
        return axios.get(this.baseUrl + apiUrl, this.config(isToken, headers, params)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }

    // ******************* LARAVEL API PUT REQUEST WITH IMAGE ************************ */
    static updateFormRequest(apiUrl, isToken = true, formData = null, headers = null, params = null) {
        baseParam = { "_method": "PUT" }
        if (params != null) {
            // var baseParam = $.extend(params, baseParam)
            var baseParam = Object.assign(params, baseParam)
        }
        return axios.post(this.baseUrl + apiUrl, formData, this.config(isToken, headers, baseParam)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }

    // ******************* LARAVEL API PUT REQUEST WITH APPLICATION JSON FORMAT ************************ 
    static updateRequest(apiUrl, isToken = true, formData = null, headers = null, params = null) {

        return axios.put(this.baseUrl + apiUrl, formData, this.config(isToken, headers, params)).then(result => {
            return result;
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        });
    }

    // *********************************** LARAVEL API DELETE REQUEST *************************************************** 
    static deleteRequest(apiUrl, isToken = true, headers = null, params = null) {
        return axios.delete(this.baseUrl + apiUrl, this.config(isToken, headers, params)).then(result => {
            return result
        }).catch(error => {
            if (error.response.status == 401) {
                this.unauthenticateRedirect();
            }
        })
    }

    // ******************************* CONFIGURATION OF HEADER AND PARAMETERS ******************************* 
    static config(isToken = true, headers = null, parameters = null) {
        var defaultHeaders = {
            Accept: "application/json"
        };
        var merge = {};
        if (isToken) {
            var token = { Authorization: "Bearer " + localStorage.getItem("token") }
            merge = Object.assign(defaultHeaders, token)
        }
        merge = Object.assign(defaultHeaders, headers)
        return {
            headers: merge,
            params: parameters
        }
    }

    // ********************************* UN[AUTHENTICATION] ERROR ************************************** 
    static unauthenticateRedirect() {
        localStorage.clear();
        location.replace('/login'); // go to login page 
    }

}