<template>
<div>
    <section class="login-sec">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-5 mx-auto">
                    <form class="form" @submit.prevent="formSubmit">
                        <div class="row form-row">
                            <div class="col-xl-12 col-lg-12 col-md-12 mb-4">
                                <div class="input-group">
                                    <span class="input-group-text" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="fill: var(--white)">
                                            <path d="M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm0 2v.511l-8 6.223-8-6.222V6h16zM4 18V9.044l7.386 5.745a.994.994 0 0 0 1.228 0L20 9.044 20.002 18H4z"></path>
                                        </svg></span>
                                    <input v-model="email" type="text" class="form-control" placeholder="Enter Your Email" aria-label="Username" aria-describedby="basic-addon1" />
                                </div>
                                <div class="input-errors" v-for="error of v$.email.$errors" :key="error.$uid">
                                    <div class="error-msg">{{ error.$message }}</div>
                                </div>
                            </div>
                            <!--col-xl-12 col-lg-12 col-md-12-->

                            <div class="col-xl-12 col-lg-12 col-md-12 mb-4">
                                <div class="input-group">
                                    <span class="input-group-text" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" style="fill: var(--white)">
                                            <path d="M3.433 17.325 3.079 19.8a1 1 0 0 0 1.131 1.131l2.475-.354C7.06 20.524 8 18 8 18s.472.405.665.466c.412.13.813-.274.948-.684L10 16.01s.577.292.786.335c.266.055.524-.109.707-.293a.988.988 0 0 0 .241-.391L12 14.01s.675.187.906.214c.263.03.519-.104.707-.293l1.138-1.137a5.502 5.502 0 0 0 5.581-1.338 5.507 5.507 0 0 0 0-7.778 5.507 5.507 0 0 0-7.778 0 5.5 5.5 0 0 0-1.338 5.581l-7.501 7.5a.994.994 0 0 0-.282.566zM18.504 5.506a2.919 2.919 0 0 1 0 4.122l-4.122-4.122a2.919 2.919 0 0 1 4.122 0z"></path>
                                        </svg></span>
                                    <input v-model="password" :type="hidden ? 'password' : 'text'" class="form-control" placeholder="Password" aria-label="Username" aria-describedby="basic-addon1" />
                                    <span class="input-group-text" id="basic-addon2"><img :src="hidden ? hide : show" alt="" class="img-fluid" @click="hidden = !hidden" /></span>
                                </div>
                                <div class="input-errors" v-for="error of v$.password.$errors" :key="error.$uid">
                                    <div class="error-msg">{{ error.$message }}</div>
                                </div>
                            </div>
                            <!--col-xl-12 col-lg-12 col-md-12-->

                            <div class="col-xl-12 col-lg-12 col-md-12">
                                <div class="submit-btn">
                                    <button class="btn btn-primary" type="submit" v-if="!loading">
                                        Login
                                    </button>

                                    <button class="btn btn-primary" type="button" v-else>
                                        <div class="spinner-border text-light" role="status">
                                            <span class="visually-hidden"></span>
                                        </div>
                                    </button>
                                </div>
                                <!--submit-btn-->
                            </div>
                            <!--col-xl-12 col-lg-12 col-md-12-->
                        </div>
                        <!--row form-row-->
                    </form>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script>
import hide from "@/assets/images/icons/hide.png";
import show from "@/assets/images/icons/show.png";
import useVuelidate from "@vuelidate/core";
import {
    required,
    email,
    helpers
} from "@vuelidate/validators";
import ApiClass from "@/api/api.js";

export default {
    name: "LoginView",
    setup() {
        return {
            v$: useVuelidate()
        };
    },
    data() {
        return {
            hidden: true,
            hide: hide,
            show: show,
            email: "",
            password: "",
            loading: false,
        };
    },

    methods: {
        formReset() {
            this.email = "";
            this.number = "";
            this.password = "";
            this.v$.$reset(); // reset validation
        },

        async formSubmit() {
            const result = await this.v$.$validate();
            if (!result) {
                // notify user form is invalid
                return;
            }

            let form_data = {
                email: this.email,
                password: this.password,
            };
            // Call Api
            this.loading = true;
            let response = await ApiClass.postRequest("login", false, form_data);
            console.log(result, response);

            if (response?.data) {
                this.loading = false;
                if (response.data.status_code == "1") {
                    //return success message
                    console.log(response.data.message);

                    this.$swal({
                        toast: true,
                        position: "top-end",
                        icon: "success",
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 3000,
                    });

                    this.formReset();
                    // Save User Info In Local Storage
                    localStorage.setItem(
                        "user_info",
                        JSON.stringify(response.data.data.user_info)
                    );
                    localStorage.setItem("token", response.data.data.access_token);
                    // Push To Dashboard
                    this.$router.push("/blog");

                    return;
                }
                if (response.data.status_code == "0") {
                    //return error message
                    console.log(response.data.message);

                    this.$swal({
                        toast: true,
                        position: "top-end",
                        icon: "error",
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 3000,
                    });

                    return;
                }
            }

            this.loading = false;
        },
    },

    validations() {
        return {
            email: {
                required: helpers.withMessage("Email is Required", required),
                email: helpers.withMessage("Invalid Email Address", email),
            },
            password: {
                required: helpers.withMessage("Password is Required", required),
            },
        };
    },
};
</script>

<style scoped>
.login-sec {
    background-image: url(../assets/images/login/login_bg.png);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form {
    padding: 50px 35px;
    position: relative;
    z-index: 999;
    background: var(--form-bg);
    box-shadow: var(--form-shadow);
    border-radius: 10px;
    border: 1px solid var(--form-border);
}

.input-group {
    box-shadow: var(--input-shadow);
}

.input-group .form-control {
    min-height: 50px;
    background-color: var(--white);
    border-radius: 3px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    color: var(--td-color);
}

.input-group .form-control:focus {
    outline: none;
    box-shadow: none;
}

.input-group .input-group-text {
    background: linear-gradient(45deg, #0f71ef, #28c7f8);
    min-height: 40px;
    padding: 0 15px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.input-group .form-control::placeholder {
    color: var(--black);
}

.submit-btn .btn-primary {
    width: 100%;
    background: var(--gradient);
    border: none;
    min-height: 45px;
    font-weight: 500;
    font-size: 17px;
    color: var(--white);
}

.submit-btn .btn-primary:focus {
    box-shadow: none;
}

#basic-addon2 {
    background: var(--white);
    cursor: pointer;
}
</style>
