import { createApp } from 'vue'
import App from './App.vue'
import router from './router'



//BOOTSTRAP
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

//SWEETALERT
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

//QUILL EDITOR
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

//COMMON CSS
import './assets/css/common.css';

import $ from 'jquery'
// import '@/assets/js/summernote.min.css'
// import '@/assets/js/summernote.min.js'
// import '@/assets/js/summernote-bs4.css'
// import '@/assets/js/summernote-bs4.js'
// import '@/assets/js/summernote-lite.min.js'
// import '@/assets/js/summernote-lite.min.css'
import SummernoteEditor from 'vue3-summernote-editor';



 

createApp(App).use(router).use(VueSweetalert2).use($).component('SummernoteEditor', SummernoteEditor).component('QuillEditor', QuillEditor).mount('#app')