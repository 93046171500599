import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'

const routes = [{
        path: '/',
        name: 'login',
        component: LoginView
    },
    {
        path: '/blog',
        name: 'blog',
        component: () =>
            import ( /* webpackChunkName: "blog" */ '../views/BlogView.vue')
    },

    {
        path: '/create-blog',
        name: 'createblog',
        component: () =>
            import ( /* webpackChunkName: "createblog" */ '../views/CreateBlogView.vue')
    },

    {
        path: '/edit-blog/:id',
        name: 'edit-blog',
        component: () =>
            import ( /* webpackChunkName: "edit-blog" */ '../views/CreateBlogView.vue')
    },

    {
        path: '/category',
        name: 'category',
        component: () =>
            import ( /* webpackChunkName: "category" */ '../views/CategoryView.vue'),
    },
    {
        path: '/:page/create',
        name: 'create',
        component: () =>
            import ( /* webpackChunkName: "create" */ '../views/CreateView.vue')
    },

    {
        path: '/:page/edit/:id',
        name: 'edit',
        component: () =>
            import ( /* webpackChunkName: "edit" */ '../views/CreateView.vue')
    },


    {
        path: '/tags',
        name: 'tags',
        component: () =>
            import ( /* webpackChunkName: "tags" */ '../views/TagsView.vue')
    },
 

    
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router