<template>
  <div id="app">
    <!-- <Header/> -->
    <router-view />
  </div>
</template>


<script>
// import Header from '@/components/Header.vue'
export default {
  name: "App",
  components: {
    // Header
  },
};
</script>


<style>

body {
    background: var(--body-bg) !important;
}
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.error-msg {
  color: var(--red);
  font-size: 12px;
  font-weight: 500;
}

.spinner-border {
  width: 1.2rem !important;
  height: 1.2rem !important;
}


.loader-box {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


.loader-box .spinner-border {
    width: 2rem !important;
    height: 2rem !important;
}

:root {
    --body-bg:#E9ECEF;
    --red:#dc3545;
    --green:#28a745;
  --black: #000;
  --section-bg: #cdd9ff;
  --white: #fff;
  --wrapper-bg: #fcfcfc;
  --box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  --input-shadow: 1px 1px 10px #0000000d;
  --gradient: linear-gradient(45deg, #0f71ef, #28c7f8);
  --form-bg: rgba(255, 255, 255, 0);
  --form-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  --form-border: rgba(255, 255, 255, 0.18);
  --navbar-box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.1);
  --sidebar: #1d2939;
  --btn-color:#138496;
  --table-heading:#989090;
  --td-color:#343a40;
  --link-color:#338ecf;
  --form-border-2:#ced4da;
  --yellow:#ff921d;
  --blog-color:#8c8b8b;
  --gray:#eee;
  --facebook:#5869da;
  --twitter:#55acee;
  --linkedin:#0e76a8;
  --show-blog:#212529;
}

@font-face {
  font-family: Roboto;
  src: url(./assets/font/Roboto-Thin.ttf);
  font-weight: 100;
}



@font-face {
  font-family: Roboto;
  src: url(./assets/font/Roboto-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: Roboto;
  src: url(./assets/font/Roboto-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url(./assets/font/Roboto-Medium.ttf);
  font-weight: 500;
}


@font-face {
  font-family: Roboto;
  src: url(./assets/font/Roboto-Bold.ttf);
  font-weight: 700;
}



@font-face {
  font-family: Roboto;
  src: url(./assets/font/Roboto-Black.ttf);
  font-weight: 900;
}
</style>